<template>
    <div class="page-warp gray-page min-page">
        <van-tabs v-model="active" @click="resetLoad()">
            <van-tab title="全部"></van-tab>
            <van-tab title="待支付" name="0"></van-tab>
            <van-tab title="注册中" name="2"></van-tab>
            <van-tab title="已完成" name="3"></van-tab>
            <van-tab title="已取消" name="100"></van-tab>
        </van-tabs>
        <div class="scroll-box">
             <div class="row loop-list mt-10"  v-for="(item,inx) in loopList" :key="inx">
                <div class="row loop-title" @click="todetail(item)"><span class="nums">订单号：{{item.orderNo}}</span>
                <span class="btns">{{cms[item.individualOrderStatus]}}</span>
                </div>
                <div class="row loop-cons right-jt" @click="todetail(item)">
                    <span class="fz-12">{{item.areaName}}</span>
                    <span class="fz-12">行业类型：{{item.industryName}}</span>
                    <span class="fz-12">注册服务费：{{item.registrationServiceFeeD}}元</span>
                    <span class="fz-12">创建时间：{{item.createTime}}</span>
                </div>
                <div class="row loop-bottom cons-btn" v-if="item.individualOrderStatus == 0">
                    <div class="o-b-btn ons" @click="toCancel(item)">取消</div>
                    <div class="o-b-btn ons" @click="toZF(item)">支付</div>
                </div>
            </div>
            <infinite-loading 
                v-if="!finished" 
                @infinite="onLoad"
                ref="infiniteLoading">
            </infinite-loading>
            <div v-else class="row no-datas">没有更多了</div>
        </div>
        <van-dialog v-model="diaShow" title="是否已汇款到" @confirm="toZFSub()" show-cancel-button>
            <div class="row dialog-main">
                <div class="row"><span>丰烨（山东）产业园管理有限公司</span></div>
                <div class="row"><span>开户行：中国建设银行股份有限公司青岛四方支行</span><span @click="copyAccounts('中国建设银行股份有限公司青岛四方支行')" class="copy-para">&nbsp;&nbsp;</span></div>
                <div class="row mt-10"><span>账&nbsp;&nbsp;&nbsp;&nbsp;户：37150198641000001573</span><span @click="copyAccounts('37150198641000001573')" class="copy-para">&nbsp;&nbsp;</span></div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import { pageQuery,ZFById,cancelById } from "@/api/individual"
import { copyStr } from '../../assets/js/comm'
import InfiniteLoading from "vue-infinite-loading";
export default {
    data() {
        return {
            active:'',
            loading: false,
            finished: false,
            page:1,
            pageSize:10,
            loopList:[],
            cms:{
                0: '待支付',
                1: '注册中',
                2: '注册中',
                3: '已完成',
                100: '已取消',
            },
            diaShow:false,
            diaSaveP: {
                id:''
            }
        };
    },
    components: {
        InfiniteLoading
    },
    created(){
        sessionStorage.setItem('individualDetail','');
    },
    methods: {
        resetLoad(){
            this.loopList = [];
            this.page = 1;
            this.loading = false;
            this.finished = false;
            this.onLoad()
        },
        todetail(res){
            sessionStorage.setItem('individualDetail',JSON.stringify(res));
            this.$router.push({
            path:'/individualDetail'})
        },
        copyAccounts(strs){
            copyStr(strs);
            this.$nextTick(()=>{
                this.$toast.success('复制成功');
            })
        },
        toCancel(row){
            let that = this;
            this.$dialog.confirm({
                title: '取消',
                message: `是否取消：${row.orderNo}？`,
                // messageAlign:'left',
                beforeClose:function(action,done){
                    if (action === 'confirm') {
                        cancelById({id:row.id}).then(res=>{
                            that.resetLoad();
                            this.$toast.success(res.msg);
                            done();
                        }).catch(err => {
                            this.$toast.fail(err);
                            done();
                        });
                    }else{
                        done();
                    }                    
                }
            });
        },
        toZF(row){
            let that = this;
            this.diaShow = true;
            this.diaSaveP.id = row.id
        },
        toZFSub(){
            let that = this;
            ZFById({id:this.diaSaveP.id}).then(res=>{
                that.resetLoad();
                this.$toast.success(res.msg);
            }).catch(err => {
                this.$toast.fail(err);
            });

        },
        async onLoad($state) {
            if(this.loading) return false;
            // 异步更新数据
            let pramas={
                pageNum:this.page,
                pageSize:this.pageSize
            }
            if(this.active){
                pramas.individualOrderStatus = this.active;
            }
            this.loading = true;
            await pageQuery (pramas).then(res=>{
                this.loopList = this.loopList.concat(res.rows);
                if (res.total <= this.loopList.length) {
                    this.finished = true;
                }
                this.page +=1
                setTimeout(()=>{
                    this.loading = false;
                    
                    if(this.$refs.infiniteLoading) this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded'); //完成加载
                },1000)
            })
        },
    },
};
</script>
<style scope lang="less">
</style>